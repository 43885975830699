@import "../../../../global/styles";

.no-metrics-api {
  color: $warning;
  padding-right: 10px;
  font-size: 18px;
}

.update-available-wrapper {
  cursor: pointer;
}

.update-available {
  @include text-small;

  margin-left: 4px;
  background-color: $warning;
  color: white;
  padding: 4px;
}

.clickable {
  @include clickable-name;
}

.cluster-error {
  cursor: pointer;
  color: $error;

  > :global(.anticon) {
    margin-right: 7px;
  }
}

.actions {
  min-width: 100px !important;
}

.wakeup-batch {
  @include ant-icon-button(transparent, white);
}

.wakeup {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.setting-batch {
  @include ant-icon-button(transparent, white);
}

.setting {
  @include ant-icon-button($primary);
  margin: -8px 0;
}

.delete-batch {
  @include ant-icon-button(transparent, white);
}

.delete {
  @include ant-icon-button($error);
  margin: -8px 0;
}

.warning {
  color: $warning;
}

.sleeping-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-child {
    @include text-bold;
    text-align: center;
    color: $text-dark-90;
    margin-bottom: 16px;
  }

  > div:last-child {
    text-align: center;
  }
}

.sleeping {
  color: $info;
  white-space: nowrap;

  > span {
    margin-right: 4px;
    position: relative;
    top: -1px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.upgrade-license-link {
  display: none;
  text-align: center;
  line-height: 90%;
}

table {
  tbody > tr {
    &:hover, &:global(.hover) {
      .upgrade-license-link {
        display: inline;
      }

      .hide-on-hover {
        display: none;
      }

      .progress {
        :global(.ant-progress-inner) {
          background-color: white;
        }
      }
    }
  }
}

.table {
  tbody > tr {
    .cluster {
      color: $text-dark-90;
    }

    &:hover, &:global(.hover) {
      .cluster {
        @include clickable-name-normal;
      }
    }
  }
}
