@import '../../global/styles.scss';

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  
  .star {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.25);
    transition: color .25s;
    cursor: pointer;
    
    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
    
    &.star-filled {
      color: $warning;

      &:hover {
        color: rgba($warning, 0.85);
      }
    }
  }
}

.breadcrumb {
  font-size: 12px;
  line-height: 22px;

  > span:last-child {
    > span:first-child {
      font-weight: 600;
    }
  }
}
